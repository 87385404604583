<template>
  <div class="home">
    <div class="side">
      <div v-if="!drawer">
        <div class="side-item" @mouseover="openSide(1, '时代搜索')">{{ $t('common.searchTime') }}</div>
        <div class="side-item green" @mouseover="openSide(2, '分类搜索')">{{ $t('common.searchCate') }}</div>
        <div class="side-item khaki" @mouseover="openSide(3, '地区搜索')">{{ $t('common.searchArea') }}</div>
      </div>
    </div>
    <div class="home-main">
      <div>
        <div class="flex alc" style="margin-bottom: 20px; position: fixed; z-index: 999; margin-left: 20px">
          <!-- 时代 -->
          <el-tag closable @close="clearTile()" v-if="getTitle" style="margin-right: 20px; margin-top: 10px">
            {{ getTitle }}
          </el-tag>
          <!-- <el-tag closable @close="clearTile()" v-if="$route.query.title" style="margin-right: 20px; margin-top: 10px">
            {{ $route.query.title }}
          </el-tag> -->

          <div v-if="getTag">
            <el-tag closable @close="clearTag(item, index)" v-for="(item, index) in getTag" :key="index"
              style="margin-right: 20px; margin-top: 10px">
              <!-- <span v-if="$i18n.locale != 'en-us'">{{ item.name }}</span>
              <span v-else>{{ item.cname }}</span> -->
              {{ item.name }}
            </el-tag>
          </div>
        </div>

        <!-- <el-button type="primary">搜索</el-button> -->
      </div>

      <div class="flexRight">
        <div>
          <span>{{ $i18n.locale != 'en-us' ? '图像数量：' : 'Images Total：' }}{{ quantity.imageNum }}</span>
        </div>

        <div>
          <span>{{ $i18n.locale != 'en-us' ? '大类或纲：' : 'Trivial or Class：' }}{{ quantity.classNum }}</span>
        </div>

        <div>
          <span>{{ $i18n.locale != 'en-us' ? '属：' : 'Genus：' }}{{ quantity.genusNum }}</span>
        </div>

        <div>
          <span>{{ $i18n.locale != 'en-us' ? '种：' : 'Species：' }}{{ quantity.speciesNum }}</span>
        </div>
        <div>
          <span>{{ $i18n.locale != 'en-us' ? '标本数量： ' : 'Specimens Total：' }}{{ quantity.totalNum }}</span>
        </div>
        <div>
          <span>{{ $i18n.locale != 'en-us' ? '搜索结果数量： ' : 'Specimens results Total：' }}{{ getSearchTotal }}</span>
        </div>

        <div v-if="getCondition.range == 'info'">
          <span>{{ $i18n.locale != 'en-us' ? '图片：' : 'imageInfoNum' }}{{ quantity.imageInfoNum }}</span>
        </div>
        <div v-if="getCondition.range == 'model_3_d'">
          <span>{{ $i18n.locale != 'en-us' ? '3D模型：' : 'model3DNum：' }}{{ quantity.model3DNum }}</span>
        </div>
        <div v-if="getCondition.range == 'tomography'">
          <span>{{ $i18n.locale != 'en-us' ? '断层扫描：' : 'tomographyNum：' }}{{ quantity.tomographyNum }}</span>
        </div>
        <div v-if="getCondition.range == 'panorama'">
          <span>{{ $i18n.locale != 'en-us' ? '全景图：' : 'panoramaNum：' }}{{ quantity.panoramaNum }}</span>
        </div>
        <!-- <div>
          <span>{{ $i18n.locale != 'en-us' ? '访客：' : 'visitorCount：' }}{{ quantity.visitorCount }}</span>
        </div> -->
      </div>

      <!-- <my-no-data v-if="getPageList.length == 0" conentTop="50%"></my-no-data> -->
      <ImgItem :imgList="getPageList" :total="total" v-if="getPageList[0]"></ImgItem>
      <my-no-data v-else conentTop="50%"></my-no-data>
      <!-- <vue-waterfall-easy :imgsArr="getPageList" srcKey="thumbnailUrl" @scrollReachBottom="getData" :imgWidth="220" ref="waterfall">
        <div class="img-info" slot-scope="props">
          <div class="text">
            <div class="name">
              <span v-html="props.value.imageTitle"></span>
            </div>
            <div class="enName">
              <span v-html="props.value.speciesEn"></span>
            </div>
            <div class="useTxt" v-if="$i18n.locale == 'en-us' && props.value.ageEn">
              age:
              <span v-html="props.value.ageEn"></span>
            </div>
            <div class="useTxt" v-else-if="props.value.age">
              时代:
              <span v-html="props.value.age"></span>
            </div>

            <div class="useTxt" v-if="$i18n.locale == 'en-us' && props.value.localityEn">
              location:
              <span v-html="props.value.localityEn"></span>
            </div>
            <div class="useTxt" v-else-if="props.value.locality">
              产地：
              <span v-html="props.value.locality"></span>
            </div>
          </div>
        </div>
      </vue-waterfall-easy> -->
    </div>

    <!-- <el-drawer
      :visible.sync="drawer"
      :modal="false"
      direction="ltr"
      size="260px"
      :before-close="test"
      :wrapperClosable="false"
      style="margin: 20px 0 0 0"
    > -->
    <div class="leave" @mouseleave="closeDrawer()" v-show="drawer">
      <city v-show="searchStatus == 3"></city>
      <cate v-show="searchStatus == 2 && $i18n.locale != 'en-us'" ref="hope"></cate>
      <cateEn v-show="searchStatus == 2 && $i18n.locale == 'en-us'"></cateEn>
      <age v-show="searchStatus == 1"></age>
    </div>
    <!-- </el-drawer> -->
  </div>
</template>

<script>
import commonApi from '@/api/common/index';
import imageApi from '@/api/image/index';

import MyNoData from '@/components/noData/index.vue';
import ImgItem from '@/components/imgItem/ImgItem';
import age from './components/age.vue';
import cate from './components/cate.vue';
import cateEn from './components/cateEn.vue';
import city from './components/city.vue';
import vueWaterfallEasy from 'vue-waterfall-easy';

import { mapGetters, mapMutations } from 'vuex';
import { Uniform } from 'three';

export default {
  components: {
    ImgItem,
    vueWaterfallEasy,
    MyNoData,
    age,
    cate,
    city,
    cateEn,
  },
  data () {
    return {
      selectedOptions: '',
      imgList: [],
      drawer: false,
      searchStatus: 0,
      searchTitle: '',
      isAchiveBottom: false,
      noMore: false,
      size: 2,
      total: 0,
      showTotal: '',
      from: 1,
      quantity: {},
    };
  },
  created () { },
  computed: {
    ...mapGetters(['getPageList', 'getTag', 'getTitle', 'getCondition', 'getSearchTotal', 'searchTotal']),
  },
  mounted () {
    imageApi.dataStatistics().then((res) => {
      this.quantity = res.data;
    });
    if (this.$route.query.set) {

    } else {
      this.getImgList();
    }

    // window.onscroll = () => {

    //   if (this.getPageList.length >= this.total) {
    //     return true;
    //   }
    //   //变量scrollTop是滚动条滚动时，距离顶部的距离
    //   var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    //   //变量windowHeight是可视区的高度
    //   var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
    //   //变量scrollHeight是滚动条的总高度
    //   var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    //   //滚动条到底部的条件(距底部20px时触发加载)
    //   if (scrollTop + windowHeight >= scrollHeight && !this.isAchiveBottom && !this.noMore) {
    //     this.isAchiveBottom = true;
    //     //延时触发数据加载

    //     setTimeout(() => {
    //       //后端需要进行分页，然后前端从后端拿来实现滚动加载
    //       //这里利用数组push来模拟从后端拿到的数据
    //       this.from++;
    //       this.getImgList();
    //       this.isAchiveBottom = false;
    //     }, 1500);
    //   } else {
    //     // this.$refs.waterfall.waterfallOver();
    //   }
    // };
  },
  methods: {
    getData () {
      this.from++;
      this.getImgList();
    },
    ...mapMutations(['SET_PAGE_LIST', 'SET_TAG', 'DEL_TAG', 'SET_SEARCH', 'ADD_PAGE_LIST', 'SET_TITLE', 'SET_TOTAL']),
    async clearTile () {
      // this.SET_TITLE('');
      // let str = this.getCondition.searchValue;
      // this.SET_SEARCH({
      //   searchVal: str,
      //   range: '*',
      //   from: 1,
      // });
      // const { data: res } = await commonApi.search(this.getCondition);
      // this.total = res.total;

      // this.SET_PAGE_LIST(res.rowsMap);
      // this.SET_TOTAL(res.total);
      this.$router.push({
        path: '/home/newpage',
        query: {
          set: true,
        },
      });
    },
    async clearTag (val, index) {


      this.DEL_TAG(index);
      let str = this.getCondition.searchValue;
      this.SET_SEARCH({
        searchVal: str,
        range: '*',
        from: 1,
      });
      const { data: res } = await commonApi.search(this.getCondition);
      this.total = res.total;

      this.SET_PAGE_LIST(res.rowsMap);
      this.SET_TOTAL(res.total);
    },
    test (done) {

    },
    closeDrawer () {
      this.drawer = false;
    },
    openSide (i, name) {
      this.drawer = true;
      this.searchStatus = i;
      this.searchTitle = name;
    },
    async getImgList () {
      let str = this.getCondition.searchValue;
      if (this.$route.query.range) {
        this.SET_TITLE(this.$route.query.title);
        this.SET_SEARCH({
          searchVal: this.searchVal,
          range: this.$route.query.range,
          from: 1,
        });
      }
      else {
        this.SET_SEARCH({
          searchVal: str,
          range: this.getCondition.range || '*',
          from: this.from,
        });
      }


      const { data: res } = await commonApi.search(this.getCondition);
      if (res) {
        this.total = res.total;

        if (res.pageNum == 1) {
          this.SET_PAGE_LIST(res.rowsMap);
          this.SET_TOTAL(res.total);
        } else {
          this.ADD_PAGE_LIST(res.rowsMap);
          this.SET_TOTAL(res.total);
        }
        this.from = res.pageNum;
      }
      localStorage.removeItem("title")
    },
  },
};
</script>

<style scoped lang="scss">
.text {
  box-sizing: border-box;
  padding: 10px 10px 20px 10px;
  width: 100%;
  height: auto;
  box-sizing: border-box;

  .name {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 600;
    color: #333333;
    line-height: 20px;
  }

  .enName {
    font-style: italic;
    font-size: 14px;
    font-family: Arial-BoldItalicMT, Arial;
    font-weight: 600;
    color: #333333;
    line-height: 16px;
    margin-bottom: 8px;
  }

  .useTxt {
    font-size: 12px;
    margin-bottom: 8px;
    font-weight: 600;
    font-weight: 400;
    color: #000000;
    line-height: 16px;
  }
}

.flexRight {
  position: fixed;
  background: #1890ff;
  color: #fff;
  width: 180px;
  top: 14%;
  right: 1%;
  font-size: 12px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 12px;
  line-height: 20px;
  z-index: 100;
}

.leave {
  width: 280px;
  height: 80%;
  background: #ffffff;
  box-sizing: border-box;
  position: fixed;
  top: 15%;
  left: 0;
  z-index: 2022;
  // border: 1px solid black;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 40%);
  overflow: scroll;
  transition: width 2s;
}

::v-deep .el-drawer {
  padding-top: 20px;

  header {
    display: none;
  }
}

.home-main {
  height: 100%;
  margin: 0 auto;
  padding-bottom: 20px;
}

.side {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  .side-item {
    width: 46px;
    height: 134px;
    background: #1890ff;
    border-radius: 0px 12px 12px 0px;
    writing-mode: vertical-rl;
    text-align: center;
    line-height: 46px;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: 4px;
    font-weight: 400;
    margin-bottom: 17px;
    color: #ffffff;
  }

  .green {
    background: #4ef07f;
  }

  .khaki {
    background: #f78c00;
  }
}
</style>
