<template>
  <div class="w50">
    <!-- <treeselect
      :multiple="true"
      :options="list"
      @select="getNode"
      :alwaysOpen="true"
      :normalizer="normalizer"
      placeholder="输入关键词"
      v-model="values"
      :joinValues="true"
    /> @check-change="setClear"-->

    <el-tree :data="list" show-checkbox node-key="value" @check="getReadNode" :props="defaultProps"> </el-tree>
  </div>
</template>

<script>
import commonApi from "@/api/common/index";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { regionData } from "element-china-area-data";
import { mapMutations, mapGetters } from "vuex";

export default {
  components: { Treeselect },

  data() {
    return {
      values: [],
      list: regionData,
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  created() { },
  mounted() { },
  computed: {
    ...mapGetters(["getTag", "getCondition"]),
  },
  methods: {
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.value,
        label: node.label,
        children: node.children,
      };
    },
    ...mapMutations(["SET_PAGE_LIST", "SET_TAG", "SET_SEARCH", "DEL_TAG"]),
    async setClear(data) {
      let find = this.getTag.findIndex((el) => {
        return el.id == data.value;
      });
      if (find != -1) {
        this.DEL_TAG(find);
        let str = this.getCondition.searchValue;
        this.SET_SEARCH({
          searchVal: str,
          range: "*",
          from: 1,
        });
        const { data: res } = await commonApi.search(this.getCondition);
        this.SET_PAGE_LIST(res.rowsMap);
      }
    },
    async getReadNode(data, node) {
      let find = this.getTag.findIndex((el) => el.id == data.value);
      if (find != -1) {
        this.DEL_TAG(find);
        let str = this.getCondition.searchValue;
        this.SET_SEARCH({
          searchVal: str,
          range: "*",
          from: 1,
        });
        this.searchArea();
      } else if (node.checkedKeys.length != 0) {
        let str = node.halfCheckedNodes[0] && node.halfCheckedNodes[0].label;
        str = str != undefined || "" ? str + "," : "";
        let str1 = node.halfCheckedNodes[1] && node.halfCheckedNodes[1].label;
        str1 = str1 != undefined || "" ? str1 + "," : "";
        this.SET_TAG({
          name: str + str1 + data.label,
          type: "location",
          id: data.value,
        });

        this.searchArea();
      }
    },
    async searchArea() {
      let str = this.getCondition.searchValue;
      this.SET_SEARCH({
        searchVal: str,
        range: "info",
        from: 1,
      });
      const { data: res } = await commonApi.search(this.getCondition);
      this.SET_PAGE_LIST(res.rowsMap);
      this.SET_SEARCH({
        searchVal: this.searchVal,
        range: "info",
        from: res.from,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.w50 {
  width: 100%;
}
</style>
