<template>
  <div class="filter">
    <div class="column-content-box">
      <div class="border1"></div>
      <div class="column-content">
        <!-- 第一类 -->
        <div class="cc-i-box">
          <!-- 第一列 -->
          <div class="cc-i1 cc-i" @click="navTo(data_frist[0])" :class="setClass(data_frist[0])"
            :style="{ background: data_frist[0].color }">
            <span v-if="$i18n.locale == 'en-us'">
              {{ data_frist[0].name }}
            </span>
            <span v-else>
              {{ data_frist[0].cname || data_frist[0].name }}
            </span>
          </div>
          <div class="cc-i2-item-max">
            <!-- 第二列第一排   循环这个盒子-->
            <div class="cc-i2-item" v-for="(item, index) in data1" :key="index">
              <div class="cc-i2 cc-i" @click="navTo(item.children[0])" :class="setClass(item.children[0])"
                :style="{ background: item.children[0].color }">
                <span v-if="$i18n.locale == 'en-us'">
                  {{ item.children[0].name }}
                </span>
                <span v-else>
                  {{ item.children[0].cname || item.children[0].name }}
                </span>
              </div>
              <!-- 第二列第3层 -->
              <div class="cc-i3-box">
                <div class="cc-i3 cc-ii3 cc-i" v-for="(v, i) in item.children[1].list" :key="i">
                  <div class="cc-i3-sub1" @click="navTo(v)" :class="setClass(v)" :style="{ background: v.color }">
                    <span v-if="$i18n.locale == 'en-us'">
                      {{ v.name }}
                    </span>
                    <span v-else>
                      {{ v.cname || v.name }}
                    </span>
                  </div>
                  <div class="cc-i4-box">
                    <div class="cc-i4 cc-ii2 cc-i" @click="navTo(vv)" :class="setClass(vv)"
                      :style="{ background: vv.color, height: (vv.age[1] - vv.age[0]) * 5 + 'px' }"
                      v-for="(vv, ii) in v.list" :key="ii">
                      <span v-if="$i18n.locale == 'en-us'">
                        {{ vv.name }}
                      </span>
                      <span v-else>
                        {{ vv.cname || vv.name }}
                      </span>

                      <!-- <div class="age-abs">{{ vv.age_frist_num }}</div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 第二类 -->
        <div class="cc-i-box">
          <!-- 第一列 -->
          <div class="cc-i1 cc-i" @click="navTo(data_frist[1])" :class="setClass(data_frist[1])"
            :style="{ background: data_frist[1].color }">
            <span v-if="$i18n.locale == 'en-us'">
              {{ data_frist[1].name }}
            </span>
            <span v-else>
              {{ data_frist[1].cname || data_frist[1].name }}
            </span>
          </div>
          <div class="cc-i2-item-max">
            <!-- 第二列第一排   循环这个盒子-->
            <div class="cc-i2-item" v-for="(item, index) in data2" :key="index">
              <div class="cc-i2 cc-i"
                :class="[item.children[0].name == 'Hadean' ? 'Hadean' : '', setClass(item.children[0])]"
                @click="navTo(item.children[0])" :style="{ background: item.children[0].color }">
                <span v-if="$i18n.locale == 'en-us'">
                  {{ item.children[0].name }}
                </span>
                <span v-else>
                  {{ item.children[0].cname || item.children[0].name }}
                </span>
              </div>
              <!-- 第二列第3层 -->
              <div class="cc-i3-box">
                <div :class="[v.name == 'Hadean' ? 'transparent2' : '']" class="cc-i3 cc-ii3 cc-i"
                  v-for="(v, i) in item.children[1].list" :key="i">
                  <div class="cc-i3-sub1" @click="navTo(v)" :class="setClass(v)" :style="{ background: v.color }">{{
                    v.name
                  }}</div>
                  <div class="cc-i4-box">
                    <div :class="[
                      vv.name == 'Neoarchean' || vv.name == 'Mesoarchean' || vv.name == 'Paleoarchean' || vv.name == 'Eoarchean'
                        ? 'transparent'
                        : '' || (vv.name == 'Hadean' ? 'transparent2' : ''),
                      setClass(vv),
                    ]" class="cc-i4 cc-ii2 cc-i" @click="navTo(vv)" :style="{ background: vv.color }"
                      v-for="(vv, ii) in v.list" :key="ii">
                      <span v-if="$i18n.locale == 'en-us'">
                        {{ vv.name }}
                      </span>
                      <span v-else>
                        {{ vv.cname || vv.name }}
                      </span>

                      <div class="age-abs">{{ vv.age_frist_num }}</div>
                      <!-- 就下面最后一个了，直接写死就行了 -->
                      <div class="age-abs2" v-if="vv.islast">4600</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="border1"></div>
    </div>
  </div>
</template>

<script>
import commonApi from "@/api/common/index";
import { mapMutations, mapGetters } from "vuex";
export default {
  data () {
    return {
      data_frist: [
        //每一页第层的数据
        {
          //设计稿第1页2块2层
          color: "#A2D9EF",
          age: [0.0042, 541.0],
          name: "Phanerozoic",
          cname: "显生宙",
        },
        {
          //设计稿第1页2块2层

          color: "#EB5F7E",
          age: [541.0, 4600],
          name: "Precambrian",
          cname: "前寒武纪",
        },
      ],
      data1: [
        // 第一层数据代表的是设计稿的第二列的一块（里面包含设计稿的2，3，4）
        {
          children: [
            {
              //设计稿的第二列第一个
              color: "#FCEA0F",
              age: [0.0042, 66.0],
              name: "Cenozoic",
              cname: "新生代",
            },
            {
              //设计稿的第三列第一个
              list: [
                {
                  color: "#FFF59B",
                  age: [0.0042, 2.58],
                  name: "Quaternary",
                  cname: "第四纪",
                  //设计稿的第4列第一个
                  list: [
                    {
                      color: "#FEF5E9",
                      age: [0.0042, 0.0117],
                      name: "Holocene",
                      cname: "全新世",
                      age_frist_num: 0.0042,
                    },
                    {
                      color: "#FFF1C3",
                      age: [0.0117, 2.58],
                      age_frist_num: 0.0117,
                      name: "Pleistocene",
                      cname: "更新世",
                    },
                  ],
                },
                {
                  color: "#FFDE13",
                  age: [2.58, 23.03],
                  name: "Neogene",
                  cname: "新近纪",
                  list: [
                    {
                      color: "#FFF7B2",
                      age: [2.58, 5.333],
                      age_frist_num: 2.58,
                      name: "Pliocene",
                      cname: "上新世",
                    },
                    {
                      color: "#FFED00",
                      age: [5.333, 23.03],
                      age_frist_num: 5.333,
                      name: "Miocene",
                      cname: "中新世",
                    },
                  ],
                },
                {
                  color: "#F6AC6E",
                  age: [23.03, 66.0],
                  name: "Paleogene",
                  cname: "古近纪",

                  list: [
                    {
                      color: "#FBCB97",
                      age: [23.03, 33.9],
                      age_frist_num: 23.03,
                      name: "Oligocene",
                      cname: "渐新世",
                    },
                    {
                      color: "#F9C18A",
                      age: [33.9, 56.0],
                      age_frist_num: 33.9,
                      name: "Eocene",
                      cname: "始新世",
                    },
                    {
                      color: "#F8B77C",
                      age: [56.0, 66.0],
                      age_frist_num: 56.0,
                      name: "Paleocene",
                      cname: "古新世",
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          children: [
            {
              //设计稿2层2个

              color: "#5DC4E9",
              age: [66.0, 251.902],
              name: "Mesozoic",
              cname: "中生代",
            },
            {
              //设计稿2层2个对应的第3层
              list: [
                {
                  color: "#92C362",
                  age: [66.0, 145.0],
                  name: "Cretaceous",
                  cname: "侏罗纪",
                  //设计稿的第4列第一个
                  list: [
                    {
                      color: "#BAD15E",
                      age: [66.0, 100.5],
                      age_frist_num: 66.0,
                      name: "Upper",
                      cname: "上侏罗世",
                    },
                    {
                      color: "#9FC96D",
                      age: [100.5, 145.0],
                      age_frist_num: 100.5,
                      name: "Lower",
                      cname: "中侏罗世",
                    },
                    {
                      color: "#9FC96D",
                      age: [100.5, 145.0],
                      age_frist_num: 100.5,
                      name: "Lower",
                      cname: "下侏罗世",
                    },
                  ],
                },
                // {
                //   color: "#00B0E3",
                //   age: [145, 201.3],
                //   name: "PermianJurassic",
                //   cname: "中生代",

                //   list: [
                //     {
                //       color: "#BCE3FA",
                //       age: [145, 163.5],
                //       age_frist_num: 145,
                //       name: "Upper",
                //       cname: "中生代",
                //     },

                //     {
                //       color: "#84CFEC",
                //       age: [163.5, 174.1],
                //       age_frist_num: 163.5,
                //       name: "Middle",
                //     },
                //     {
                //       color: "#00B0E8",
                //       age: [174.1, 201.3],
                //       age_frist_num: 174.1,
                //       name: "Lower",
                //     },
                //   ],
                // },
                {
                  color: "#954B96",
                  age: [201.3, 251.902],
                  name: "Triassic",
                  cname: "三叠纪",
                  list: [
                    {
                      color: "#C6A6CF",
                      age: [201.3, 237],
                      age_frist_num: 201.3,
                      name: "Upper",
                      cname: "上三叠世",
                    },

                    {
                      color: "#BB85BA",
                      age: [237, 247.2],
                      age_frist_num: 237,
                      name: "Middle",
                      cname: "中三叠世",
                    },
                    {
                      color: "#A8589E",
                      age: [247.2, 251.902],
                      age_frist_num: 247.2,
                      name: "Lower",
                      cname: "下三叠世",
                    },
                  ],
                },
              ],
            },
          ],
        },

        // 2层第3个
        {
          children: [
            {
              //设计稿2层2个

              color: "#A8C5A8",
              age: [251.902, 541.0],
              name: "Paleozoic",
              cname: "古生代",
            },
            {
              //设计稿2层3个对应的第3层
              list: [
                {
                  color: "#E35C40",
                  age: [251.902, 298.9],
                  name: "Permian",
                  cname: "二叠纪",
                  //设计稿的第4列第一个
                  list: [
                    {
                      color: "#F7BAAA",
                      age: [251.902, 259.1],
                      age_frist_num: 251.902,
                      name: "Lopingian",
                      cname: "乐平世",
                    },

                    {
                      color: "#F18E76",
                      age: [259.1, 272.95],
                      age_frist_num: 259.1,
                      name: "Guadalupian",
                      cname: "瓜德鲁普世",
                    },
                    {
                      color: "#E6755E",
                      age: [272.95, 298.9],
                      age_frist_num: 272.95,
                      name: "Cisuralian",
                      cname: "乌拉尔世",
                    },
                  ],
                },
                {
                  color: "#6DAEB4",
                  age: [298.9, 358.9],
                  name: "Carboniferous",
                  cname: "石炭纪",

                  list: [
                    {
                      color: "#A6C8CD",
                      age: [298.9, 163.5],
                      age_frist_num: 298.9,
                      name: "Pennsylvanian",
                      cname: "宾夕法尼亚亚纪",
                    },

                    {
                      color: "#769E82",
                      age: [163.5, 174.1],
                      age_frist_num: 163.5,
                      name: "Mississippian",
                      cname: "密西西比亚纪",
                    },
                  ],
                },

                {
                  color: "#D39F51",
                  age: [358.9, 419.2],
                  name: "Devonian",
                  cname: "泥盆纪",

                  list: [
                    {
                      color: "#F5E3B5",
                      age: [358.9, 382.7],
                      age_frist_num: 358.9,
                      name: "Upper",
                      cname: "上泥盆世",
                    },

                    {
                      color: "#F4CF85",
                      age: [382.7, 393.3],
                      age_frist_num: 382.7,
                      name: "Middle",
                      cname: "中泥盆世",
                    },
                    {
                      color: "#E8B86A",
                      age: [393.3, 419.2],
                      age_frist_num: 393.3,
                      name: "Lower",
                      cname: "下泥盆世",
                    },
                  ],
                },

                //Silurian

                {
                  color: "#C0DFCD",
                  age: [419.2, 443.8],
                  name: "Silurian",
                  cname: "志留纪",

                  list: [
                    {
                      color: "#EBF4EC",
                      age: [419.2, 423.0],
                      age_frist_num: 419.2,
                      name: "Pridoli",
                      cname: "普里道利世",
                    },

                    {
                      color: "#CAE6E0",
                      age: [423.0, 427.4],
                      age_frist_num: 423.0,
                      name: "Ludlow",
                      cname: "罗德洛世",
                    },
                    {
                      color: "#BFE0D7",
                      age: [427.4, 433.4],
                      age_frist_num: 427.4,
                      name: "Wenlock",
                      cname: "温洛克世",
                    },
                    {
                      color: "#A5D5CB",
                      age: [433.4, 443.8],
                      age_frist_num: 433.4,
                      name: "Llandovery",
                      cname: "兰多维列世",
                    },
                  ],
                },

                //Ordovician

                {
                  color: "#009881",
                  age: [443.8, 485.4],
                  name: "Ordovician",
                  cname: "奥陶纪",

                  list: [
                    {
                      color: "#8BC9AD",
                      age: [443.8, 458.4],
                      age_frist_num: 443.8,
                      name: "Upper",
                      cname: "上奥陶纪",
                    },

                    {
                      color: "#3EB497",
                      age: [458.4, 470.0],
                      age_frist_num: 458.4,
                      name: "Middle",
                      cname: "中奥陶纪",
                    },
                    {
                      color: "#00A082",
                      age: [470.0, 485.4],
                      age_frist_num: 470.0,
                      name: "Lower",
                      cname: "下奥陶纪",
                    },
                  ],
                },
                //Cambrian
                {
                  color: "#93AB71",
                  age: [485.4, 541.0],
                  name: "Cambrian",
                  cname: "寒武纪",

                  list: [
                    {
                      color: "#C2DDAF",
                      age: [485.4, 497],
                      age_frist_num: 485.4,
                      name: "Furongian",
                      cname: "芙蓉世",
                    },
                    {
                      color: "#B6D0A2",
                      age: [497, 509],
                      age_frist_num: 497,
                      name: "Miaolingian",
                      cname: "苗岭世",
                    },
                    {
                      color: "#AAC394",
                      age: [509, 521],
                      age_frist_num: 509,
                      name: "Series",
                      cname: "第二世",
                    },
                    {
                      color: "#9EB888",
                      age: [521, 541.0],
                      age_frist_num: 521,
                      name: "Terreneuvian",
                      cname: "纽芬兰世",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      // 第2类
      data2: [
        // 第一层数据代表的是设计稿的第二列的一块（里面包含设计稿的2，3，4）
        {
          children: [
            {
              // Proterozoic

              color: "#EA5172",
              age: [541.0, 2500],
              name: "Proterozoic",
              cname: "古元古代",
            },
            {
              //设计稿的第三列第一个
              list: [
                {
                  color: "#FABD5D",
                  age: [541.0, 1000],
                  name: "Neoproterozoic",
                  cname: "新元古代",

                  list: [
                    {
                      color: "#FFDB88",
                      age: [541.0, 635],
                      age_frist_num: 541.0,
                      name: "Ediacaran",
                      cname: "埃迪卡拉纪",
                    },

                    {
                      color: "#FDD279",
                      age: [635, 720],
                      age_frist_num: 635,
                      name: "Cryogenian",
                      cname: "成冰纪",
                    },
                    {
                      color: "#FCC86B",
                      age: [720, 1000],
                      age_frist_num: 720,
                      name: "Tonian",
                      cname: "拉伸纪",
                    },
                  ],
                },
                {
                  color: "#FAC07F",
                  age: [1000, 1600],
                  name: "Mesoproterozoic",
                  cname: "中元古代",

                  list: [
                    {
                      color: "#FDDFB2",
                      age: [1000, 1200],
                      age_frist_num: 1000,
                      name: "Stenian",
                      cname: "狭带纪",
                    },
                    {
                      color: "#FCD5A4",
                      age: [1200, 1400],
                      age_frist_num: 1200,
                      name: "Ectasian",
                      cname: "延展纪",
                    },
                    {
                      color: "#FBCB97",
                      age: [1400, 1600],
                      age_frist_num: 1400,
                      name: "Calymmian",
                      cname: "盖层纪",
                    },
                  ],
                },
                {
                  color: "#EB5F7E",
                  age: [1600, 2500],
                  name: "Paleoproterozoic",
                  cname: "古元古代",
                  list: [
                    {
                      color: "#F092B0",
                      age: [1600, 1800],
                      age_frist_num: 1600,
                      name: "Statherian",
                      cname: "固结纪",
                    },
                    {
                      color: "#EF86A3",
                      age: [1800, 2050],
                      age_frist_num: 1800,
                      name: "Orosirian",
                      cname: "造山纪",
                    },
                    {
                      color: "#EE7996",
                      age: [2050, 2300],
                      age_frist_num: 2050,
                      name: "Rhyacian",
                      cname: "层侵纪",
                    },
                    {
                      color: "#ED6C8A",
                      age: [2300, 2500],
                      age_frist_num: 2300,
                      name: "Siderian",
                      cname: "成铁纪",
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          children: [
            {
              color: "#E5007D",
              age: [2500, 4000],
              name: "Archean",
            },
            {
              list: [
                {
                  color: "#F5B4CB",
                  age: [2500, 2800],
                  name: "Neoarchean",
                  list: [
                    {
                      color: "#F5B4CB",
                      age: [2500, 2800],
                      age_frist_num: 2500,
                      name: "Neoarchean",
                    },
                  ],
                },
                {
                  color: "#EF87AF",
                  age: [2800, 3200],
                  name: "Mesoarchean",

                  list: [
                    {
                      color: "#EF87AF",
                      age: [2800, 3200],
                      age_frist_num: 2800,
                      name: "Mesoarchean",
                    },
                  ],
                },
                {
                  color: "#EB609F",
                  age: [3200, 3600],
                  name: "Paleoarchean",
                  list: [
                    {
                      color: "#EB609F",
                      age: [3200, 3600],
                      age_frist_num: 3200,
                      name: "Paleoarchean",
                    },
                  ],
                },
                {
                  color: "#EB609F",
                  age: [3600, 4000],
                  name: "Eoarchean",
                  list: [
                    {
                      color: "#EB609F",
                      age: [3600, 4000],
                      age_frist_num: 3600,
                      name: "Eoarchean",
                    },
                  ],
                },
              ],
            },
          ],
        },

        // 2层第3个
        {
          children: [
            {
              //设计稿2层2个
              color: "#B70D7F",
              age: [4000, 4600],
              name: "Hadean",
            },
            {
              //设计稿2层3个对应的第3层
              list: [
                {
                  color: "#B70D7F",
                  age: [4000, 4600],
                  name: "Hadean",
                  list: [
                    {
                      color: "#B70D7F",
                      age: [4000, 4600],
                      age_frist_num: 4000,
                      name: "Hadean",
                      islast: true,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getTag", "getCondition"]),
  },
  mounted () { },
  methods: {
    ...mapMutations(["SET_PAGE_LIST", "SET_TAG", "SET_SEARCH"]),
    setClass (val) {
      let index = this.getTag.findIndex((el) => {
        if (el.type == "ageRange" && el.name == val) {
        }
      });

      if (index != -1) {
        return "showdow";
      }
    },
    async navTo (item) {
      this.SET_TAG({
        name: item.name,
        type: "ageRange",
        age: item.age,
      });
      let str = this.getCondition.searchValue;
      this.SET_SEARCH({
        searchVal: str,
        range: "info",
        from: 1,
      });
      const { data: res } = await commonApi.search(this.getCondition);
      this.SET_PAGE_LIST(res.rowsMap);
      this.SET_SEARCH({
        searchVal: this.searchVal,
        range: "info",
        from: res.from,
      });
    },
  },
};
</script>
<style lang="scss">
.showdow {
  box-shadow: inset 0 0 6px;
}

// box-shadow: inset 0 0 6px
//  .cc-i2-item  最后注释掉的，flex:1
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}

.filter {
  background: #fff;
  width: 100%;
  font-size: 10px;
  height: 520px;

  .column-title {
    text-align: center;
    width: 100%;
    height: 80rpx;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    font-size: 30rpx;
    padding-left: 10rpx;
    box-sizing: border-box;
  }

  .column-content-box {
    display: flex;
    // justify-content: space-between;
    margin-bottom: 20px;

    .column-content {
      // 中间的盒子
      width: 100%;

      // border: 1px solid #333333;
      .cc-i-box {
        display: flex;
        justify-content: center;

        // 每一块
        .cc-i {
          padding: 10rpx;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          word-break: break-all;
          justify-content: center;
          text-align: center;
          border: 1px solid #333333;
          margin: 0px -1px -1px 0;
        }

        .cc-ii2 {
          margin: -1px -1px 0px -2px;
        }

        .cc-i4:last-child {
          border-bottom: 0 !important;
        }

        .cc-i1 {
          width: 15%;
        }

        .cc-i2-item-max {
          width: 85%;
          display: flex;
          flex-direction: column;

          background-color: #f1f1f1;

          .cc-i2-item {
            width: 100%;
            // flex: 1;  //最后注释掉的
            display: flex;

            .cc-i2 {
              width: 20%;
              word-wrap: break-word;
              /*英文的时候需要加上这句，自动换行*/
              // flex: 1;
              // min-height:80rpx;
            }
          }
        }

        .cc-i3-box {
          // width: 35%;
          display: flex;
          flex: 1;
          flex-direction: column;

          .cc-i3 {
            // min-height:80rpx;
            flex: 1;
            display: flex;
            padding: 0 !important;

            .cc-i3-sub1 {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              width: 45%;
            }

            .cc-i4-box {
              width: 55%;

              height: 100%;
              display: flex;
              flex-direction: column;
              flex: 1;

              .cc-i4 {
                // min-height:80rpx;
                // flex: 1;
                padding: 10px 0;
                line-height: 300%;
                position: relative;
                padding: 40rpx 0 !important;
              }
            }
          }
        }
      }
    }
  }
}

// 最后一个
.cc-i2-item-max .Hadean {
  width: 40% !important;
  border-left: 0 !important;
  writing-mode: inherit !important;
}

.transparent {
  color: transparent !important;
}

.transparent2 {
  border-left: 0 !important;
  color: transparent !important;
}

.cc-i1 {
  // writing-mode: vertical-lr !important;
  writing-mode: tb;

  // writing-mode: vertical-rl;
}

.cc-i2 {
  writing-mode: vertical-lr;
}

.age-abs {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  line-height: 1;
  color: #333333;
  transform: translate(101%, -50%);
}

.age-abs2 {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 12px;
  line-height: 1;
  color: #333333;
  transform: translate(101%, 50%);
}
</style>
