<template>
  <div class="personal-ImgList" ref="element">
    <!-- <vue-flex-waterfall :col="5" :break-by-container="true" col-spacing="10"> -->
    <vue-waterfall-easy :imgsArr="getPageList" srcKey="thumbnail_url" @click="goDetails" @scrollReachBottom="getData"
      :imgWidth="220" ref="waterfall">
      <div class="img-info" slot-scope="props">
        <div class="text">
          <div class="name">
            <span v-html="props.value.image_title"></span>
          </div>
          <div class="enName">
            <span v-html="props.value.speciesEn"></span>
          </div>
          <div class="useTxt"
            v-if="$i18n.locale == 'en-us' && props.value.epoch && props.value.topic_type != 3 && props.value.topic_type != 4">
            age:
            <span v-html="props.value.epoch"></span>
          </div>
          <div class="useTxt" v-else-if="props.value.epoch && props.value.topic_type != 3 && props.value.topic_type != 4">
            时代：
            <span v-html="props.value.epoch"></span>
          </div>
          <div class="useTxt"
            v-if="$i18n.locale == 'en-us' && !props.value.epoch && props.value.topic_type != 3 && props.value.topic_type != 4 && props.value.period">
            age:
            <span v-html="props.value.period + '(' + props.value.age_from + '-' + props.value.age_to + ')'"></span>
          </div>
          <div class="useTxt"
            v-else-if="!props.value.epoch && props.value.topic_type != 3 && props.value.topic_type != 4 && props.value.period">
            时代：
            <span v-html="props.value.period + '(' + props.value.age_from + '-' + props.value.age_to + ')'"></span>
          </div>

          <div class="useTxt"
            v-if="$i18n.locale == 'en-us' && props.value.topic_type != 3 && props.value.topic_type != 4">
            location:
            <span v-html="props.value.locality_en"></span>
          </div>
          <div class="useTxt"
            v-else-if="props.value.locality && props.value.topic_type != 3 && props.value.topic_type != 4">
            产地：
            <span v-html="props.value.locality"></span>
          </div>
        </div>
      </div>

      <!-- <div class="chunk" v-for="(item, index) in imgList" :key="index" @click="goDetails(item)">
        <div class="img">
          <img :src="item.thumbnailUrl" />
        </div>
        <div class="text">
          <div class="name">
            <span v-html="item.image_title"></span>
          </div>
          <div class="enName">
            <span v-html="item.speciesEn"></span>
          </div>

          <div class="useTxt" v-if="$i18n.locale == 'en-us' && item.ageEn">
            age:
            <span v-html="item.ageEn"></span>
          </div>
          <div class="useTxt" v-else-if="item.age">
            时代:
            <span v-html="item.age"></span>
          </div>

          <div class="useTxt" v-if="$i18n.locale == 'en-us' && item.locality_en">
            location:
            <span v-html="item.locality_en"></span>
          </div>
          <div class="useTxt" v-else-if="item.locality">
            产地：
            <span v-html="item.locality"></span>
          </div>
        </div>
      </div> -->
      <!-- </vue-flex-waterfall> -->
    </vue-waterfall-easy>
  </div>
</template>

<script>
import VueFlexWaterfall from 'vue-flex-waterfall';
import vueWaterfallEasy from 'vue-waterfall-easy';
import { mapGetters, mapMutations } from 'vuex';
import commonApi from '@/api/common/index';

export default {
  name: 'imgItem',
  components: {
    VueFlexWaterfall,
    vueWaterfallEasy,
  },
  props: {
    imgList: {
      type: Array,
      required: true,
    },
    type: {
      type: Number,
    },
    total: {
      type: Number,
    },
  },

  data () {
    return {
      from: '',
    };
  },
  computed: {
    ...mapGetters(['getPageList', 'getTag', 'getTitle', 'getCondition']),
  },
  mounted () {
    // this.getData();
  },
  watch: {
    getPageList (newVal, oldVal) {


    }
  },
  methods: {
    ...mapMutations(['SET_PAGE_LIST', 'SET_TAG', 'DEL_TAG', 'SET_SEARCH', 'ADD_PAGE_LIST', 'SET_TITLE']),

    async getData () {
      this.$parent.getData();
      if (this.getPageList.length == this.total) {
        this.$refs.waterfall.waterfallOver();
      }
    },

    goAdd (item) {
      if (this.type == 1) {
        this.$router.push({
          path: 'addimg',
          query: {
            id: item.infoId,
          },
        });
      }
      if (this.type == 2) {
        this.$router.push({
          path: 'add3D',
          query: {
            id: item.model3DId,
          },
        });
      }
      if (this.type == 3) {
        this.$router.push({
          path: 'addPanorama',
          query: {
            id: item.panoramaId,
          },
        });
      }
      if (this.type == 4) {
        this.$router.push({
          path: 'addTomography',
          query: {
            id: item.tomographyId,
          },
        });
      }
    },
    goDetails (e, { index, value }) {
      if (this.type && this.type != 6) {
        return this.goAdd(value);
      }
      if (value.topic_type == 1) {
        // this.$router.push({
        //   path: 'imgInfo',
        //   query: {
        //     id: value.topic_id,
        //   },
        // });
        let routeData = this.$router.resolve({
          path: 'info2',
          query: {
            id: value.topic_id,
          },
        });
        window.open(routeData.href, '_blank');
      }
      if (value.topic_type == 2) {
        this.$router.push({
          path: '3dInfo',
          query: {
            id: value.topic_id,
            range: "model_3_d"
          },
        });
        // let routeData = this.$router.resolve({
        //   path: 'info2',
        //   query: {
        //     id: value.topic_id,
        //     range: "model_3_d"
        //   },
        // });
        // window.open(routeData.href, '_blank');
      }
      if (value.topic_type == 3) {
        this.$router.push({
          path: 'panoramaInfo',
          query: {
            id: value.topic_id,
            range: "panorama"
          },
        });

        // let routeData = this.$router.resolve({
        //   path: 'info2',
        //   query: {
        //     id: value.topic_id,
        //     range: "panorama"
        //   },
        // });
        // window.open(routeData.href, '_blank');
      }
      if (value.topic_type == 4) {
        this.$router.push({
          path: 'tomographyInfo',
          query: {
            id: value.topic_id,
            range: "tomography"
          },
        });

        // let routeData = this.$router.resolve({
        //   path: 'info2',
        //   query: {
        //     id: value.topic_id,
        //     range: "tomography"
        //   },
        // });
        // window.open(routeData.href, '_blank');
      }
    },
  },
  created () { },
};
</script>
<style lang="scss">
::v-deep.img-wraper {
  border-radius: 12px 12px 0px 0;

  img {
    width: 100%;
    height: 100%;
    border-radius: 12px 12px 0px 0;
    display: block;
  }
}
</style>
<style lang="scss" scoped>
.personal-ImgList {
  width: 100%;
  margin-top: 22px;
  // min-height: 90vh;
  height: 100%;
  padding: 1px;
  // box-sizing: border-box;
  // display: flex;
  // flex-direction: column;
  // flex-wrap: wrap;
}

.text {
  box-sizing: border-box;
  padding: 10px 10px 20px 10px;
  width: 100%;
  height: auto;
  box-sizing: border-box;

  .name {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 600;
    color: #333333;
    line-height: 20px;
  }

  .enName {
    font-style: italic;
    font-size: 14px;
    font-family: Arial-BoldItalicMT, Arial;
    font-weight: 600;
    color: #333333;
    line-height: 16px;
    margin-bottom: 8px;
  }

  .useTxt {
    font-size: 12px;
    margin-bottom: 8px;
    font-weight: 600;
    font-weight: 400;
    color: #000000;
    line-height: 16px;
  }
}

.chunk {
  width: 220px;
  background: #ffffff;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  margin-bottom: 10px;
  box-sizing: border-box;
  min-height: 300px;

  .img {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: 100%;
      border-radius: 12px 12px 0px 0;
      display: block;
    }
  }
}
</style>
