<template>
  <div class="home">
    <treeselect class="tree" :multiple="true" :options="menuOptions" :alwaysOpen="true" :defaultExpandLevel="2"
      placeholder="输入关键词" v-model="values" :normalizer="normalizer" @select="handleNodeClick" @deselect="setClear" />
  </div>
</template>

<script>
import imageApi from "@/api/image/index";
import { handleTree } from "@/utils/index";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import commonApi from "@/api/common/index";
import { mapMutations, mapGetters } from "vuex";

export default {
  components: { Treeselect },
  data () {
    return {
      menuOptions: [],
      values: null,
      defaultProps: {
        children: "children",
        label: "categoryNameEn",
      },
      sortValueBy: "ORDER_SELECTED",
      Type: "categoryNameEn",
    };
  },
  created () {
    this.init();
  },
  mounted () { },
  computed: {
    ...mapGetters(["getTag", "getCondition"]),
  },
  methods: {
    ...mapMutations(["SET_PAGE_LIST", "SET_TAG", "SET_SEARCH", "DEL_TAG"]),
    async init () {
      const { rows: res } = await imageApi.listBiologicalCategory({
        pageNum: 1,
        pageSize: 10
      });
      this.menuOptions = handleTree(res, "biologicalCategoryId");
    },

    async setClear (node) {
      let find = this.getTag.findIndex((el) => {
        return el.name == node.categoryNameEn;
      });
      if (find != -1) {
        this.DEL_TAG(find);
        let str = this.getCondition.searchValue;
        this.SET_SEARCH({
          searchVal: str,
          range: "*",
          from: 1,
        });
        const { data: res } = await commonApi.search(this.getCondition);
        this.SET_PAGE_LIST(res.rowsMap);
      }
    },
    normalizer (node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.biologicalCategoryId,
        label: node[this.Type],
        children: node.children,
      };
    },
    // changeSelect
    async handleNodeClick (e) {
      this.SET_TAG({
        name: e.categoryNameEn,
        type: "category",
      });
      let str = this.getCondition.searchValue;
      this.SET_SEARCH({
        searchVal: str,
        range: "info",
        from: 1,
      });
      const { data: res } = await commonApi.search(this.getCondition);
      this.SET_PAGE_LIST(res.rowsMap);
      this.SET_SEARCH({
        searchVal: this.searchVal,
        range: "info",
        from: res.from,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.tree {
  height: 100%;

  ::v-deep {
    .vue-treeselect__menu {
      max-height: none !important;
    }
  }
}

.home {
  width: 90%;
  margin: 10px auto;
}
</style>
